@use '../lib';

.video{
  visibility: visible; // temporary added to avoid css console warning 
  &__wrapper{
    @include lib.mq($from: 'desktop') {
      height: 35rem;      
      position: relative;
      overflow: hidden;
    }

    @include lib.mq($from: 'monitor') {
      height: 40rem;
    }

    @include lib.mq($from: 'large') {
      height: 60rem;
    }

    // @include lib.mq($from: 'xlarge') {
    //   height: 60rem;
    // }

  }

  &__video{    
    width: 100%;
    @include lib.mq($from: 'desktop') {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}